<template>
  <v-container fluid class="px-8 mt-8">
    <v-card
      class="pa-6 grey lighten-4 d-flex flex-row justify-space-between"
      v-if="item"
      style="overflow: scroll"
    >
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("report_type") }}</v-list-item-subtitle>
          <v-list-item-title>
            {{ item.$calculated.templateName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("createdAt") }}</v-list-item-subtitle>
          <v-list-item-title
            >{{ item.createdAt | getDisplayDate | empty }} ({{
              item.$calculated.$createdBy | empty
            }})</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>{{ $t("updatedAt") }}</v-list-item-subtitle>
          <v-list-item-title
            >{{ item.updatedAt | getDisplayDate | empty }} ({{
              item.$calculated.$updatedBy | empty
            }})</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title style="text-align: center">
            <v-chip :color="getStatusColor(item.status)" dark>{{
              $t(item.status.split(".").pop())
            }}</v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-btn v-if="!item.queueId" text>
              <v-icon small color="blue darken-2" class="mr-2">
                mdi mdi-hand-back-right
              </v-icon>
              <span class="caption text-uppercase">{{ $t("manually") }}</span>

              <!-- <v-icon small color="green darken-2" class="mr-2">mdi mdi-hand-right</v-icon>
              <span class="caption">{{ $t("manually") }}</span> -->
            </v-btn>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-btn
              v-if="item.queueId"
              text
              :to="{
                name: 'Scheduled Report Details',
                params: { taskId: item.$calculated.task.taskId },
              }"
            >
              <v-icon small color="blue darken-2" class="mr-2"
                >mdi mdi-clock</v-icon
              >
              <span class="caption">{{ $t("scheduler") }}</span>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-divider />

    <h3 class="mt-2 body">{{ $t("generated_reports") }}</h3>
    <v-card>
      <GsDataTable
        ref="table"
        api=""
        endpoint="/reports"
        :appSetting="appSettingKey"
        :headers="headers"
        :sort-by="['createdAt']"
        :sort-desc="[true]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
        @click:row="row_click"
      >
        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="(actionId) => action_activate(actionId, item)"
          />
        </template>

        <template v-slot:item.createdAt="{ item }">
          <span
            >{{ item.createdAt | getDisplayDate }} ({{
              item.$calculated.$createdBy | empty
            }})</span
          >
        </template>

        <!-- <template v-slot:item.templateId="{ item }">
          <span
            ><strong>{{ item.$calculated.templateName }}</strong>
          </span>
        </template> -->

        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{
            $t(item.status.split(".").pop())
          }}</v-chip>
        </template>

        <template v-slot:item.inputValues_startDate="{ item }">
          {{ item.inputValues.startDate | getDisplayDate("date") | empty }}
        </template>

        <template v-slot:item.inputValues_endDate="{ item }">
          {{ item.inputValues.endDate | getDisplayDate("date") | empty }}
        </template>

        <template v-slot:item.assets="{ item }">
          {{ showAssets(item.inputValues.items) | shortenText }}
        </template>

        <template v-slot:item.download="{ item }">
          <v-icon :color="showReportFormatColor(item.format)">
            {{ showReportFormat(item.format) }}
          </v-icon>
          <!-- <v-icon
              :disabled="!isDownloadable(item)"
              :color="showReportFormatColor(item.format)"
              @click="download_click(item)"
            >
              {{ showReportFormat(item.format) }}
            </v-icon> -->

          <!-- <v-icon
              v-if="item.status.split('.').pop() === 'success'"
              :color="showReportFormatColor(item.format)"
              @click="download_click(item)"
            >
              {{ showReportFormat(item.format) }}
            </v-icon> -->
          <!-- <v-icon
              v-if="item.status.split('.').pop() !== 'success'"
              color="black"
            >
              mdi-close
            </v-icon> -->
        </template>

        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} - {{ props.pageStop }} /
          {{ props.itemsLength }}
        </template>
      </GsDataTable>
    </v-card>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const { fileSave } = require("@/utils/fileSave.js");
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";

export default {
  name: "JobDetails",
  components: {
    GsDataTable,
    GsActionsMenu,
  },
  data() {
    return {
      appSettingKey: "jobdetails",
      jobId: null,
      item: null,
      dataLoading: false,
      totalReports: 0,
      reportsPerPage: [5, 10, 15, 25, 50],
      pageLimit: 5,
      options: {},
      userLang: "en",
      headers: [
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
          width: "10%",
        },
        {
          text: this.$t("createdAt"),
          value: "createdAt",
          width: "20%",
        },
        // {
        //   text: this.$t("report_type"),
        //   sortable: false,
        //   value: "templateId",
        //   width: "10%"
        // },
        {
          text: this.$t("status"),
          sortable: false,
          value: "status",
          width: "20%",
          align: "center",
        },
        {
          text: this.$t("start_date"),
          sortable: false,
          value: "inputValues_startDate",
          width: "10%",
        },
        {
          text: this.$t("end_date"),
          sortable: false,
          value: "inputValues_endDate",
          width: "10%",
        },
        // {
        //   text: this.$t("assets"),
        //   sortable: false,
        //   value: "assets",
        //   width: "30%"
        // },
        {
          text: this.$t("format"),
          sortable: false,
          value: "download",
          width: "10%",
          align: "center",
        },
      ],
      actions: ["details"],
      // actions: ["details", "download"]
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(value) {
        this.jobId = value.params.jobId;
        this.getJob();
      },
    },
  },
  methods: {
    async getJob() {
      this.$store.state.loading = true;

      let url = `/jobs?filter=jobId:eq:${this.jobId}`;
      url += "&r8sFields=createdBy.name,updatedBy.name";
      const result = await callBffAPI({ url, method: "GET" });

      const item = result.data[0];

      await this.calculateProperties(item);
      this.item = item;

      this.$store.state.loading = false;

      // await this.refreshTable();

      this.$store.state.loading = false;
    },
    async calculateProperties(item) {
      item.$calculated = item.$calculated || {};
      item.$calculated.templateName = await this.getTemplateName(
        item.templateId
      );
      item.$calculated.$createdBy = item?.createdBy?.name || "";
      item.$calculated.$updatedBy = item?.updatedBy?.name || "";
      item.$calculated.task = await this.getTask(item.queueId);
      return item;
    },
    async refreshTable() {
      if (!this.$refs.table) return;
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      // console.log("beforeCallApi")
      // params.url += `&ownedBy=${encodeURIComponent(
      //   this.$store.state.user.ownedBy
      // )}`;
      params.url += `&filter=jobId:eq:${this.jobId}`;
      // params.url += this.$refs.dataFilter.getFilter();
      params.url += "&r8sFields=createdBy.name,updatedBy.name";
      return params;
    },
    async afterCallApi(params) {
      // const templateIds = params.items.map(p => p.templateId);
      // const templateNames = await this.getTemplateNamesByIds(templateIds);

      for (const item of params.items) {
        item.$calculated = item.$calculated || {};
        item.$calculated.$createdBy = item?.createdBy?.name || "";
        // item.$calculated.$updatedBy = item.updatedBy.name;
        // item.$calculated.templateName = templateNames[item.templateId];
      }

      return params;
    },

    isDownloadable(item) {
      return item.status.split(".").pop() === "success";
    },
    async download_click(item) {
      const reportId = item.reportId;
      const format = item.format;

      try {
        const { data, headers } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}/fileContent`,
          method: "GET",
          resType: "blob",
        });

        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : `${reportId}.${format}`;
        fileSave(data, fileName);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    details_click(item) {
      this.$router.push_safe({
        name: "Report Details",
        params: { reportId: item.reportId },
      });
    },
    action_activate(actionId, item) {
      switch (actionId) {
        case "details":
          this.details_click(item);
          break;
        case "download":
          this.download_click(item);
          break;
      }
    },
    row_click(item) {
      this.details_click(item);
    },
  },
};
</script>
